import cogoToast from "cogo-toast";
import {
    BEANS_POINT_TYPE,
    CLIENT_ID,
    CREDIT_POINT_TYPE,
} from "../../helpers/config";
import axios from "axios";
import { handleErrorRes } from "../../helpers/utils";
import TagManager from "react-gtm-module";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const userSlice = createSlice({
    name: "user",
    initialState: {
        token: null,
        user: null,
        site_access_token: null,
        site_access_token_status: "idle",
        user_profile: null,
        profile_loading: false,
        point_balances: {},
        point_to_cash_rates: {},
        point_transactions: [],
        total_point_transactions: 0,
        point_transactions_status: "idle",
        point_transfer_status: "idle",
        point_transfer_result: {},
        point_transfer_result_show: false,
        register_status: "idle",
        add_wallet_status: "idle",
        member_addresses: [],
        member_addresses_status: "idle",
        total_member_addresses: 0,
        otp_get_status: "idle",
        otp_submit_status: "idle",
    },
    reducers: {
        setToken(state, action) {
            state.token = action.payload;
        },
        setUser(state, action) {
            state.user = action.payload;
        },
        logoutUser(state, action) {
            state.user = null;
            state.token = null;
            state.user_profile = null;
            state.point_balances = {};
            state.point_to_cash_rates = {};
            state.point_transactions = [];
            state.total_point_transactions = 0;
            state.point_transactions_status = "idle";
        },
        setPointTransferResultShow(state, action) {
            state.point_transfer_result_show = action.payload;
        },
        resetLoadingStatus(state, action) {
            state.site_access_token_status = "idle";
        },
    },
    extraReducers: (builder) => {
        builder.addCase(login.fulfilled, (state, action) => {
            state.token = action.payload.token;
            state.user = action.payload.user;
            sessionStorage.removeItem("login_register_extra_params");

            TagManager.dataLayer({
                dataLayer: {
                    event: "user_registration",
                    user: {
                        id: action.payload.user?.membership_number,
                        method: "email"
                    }

                }
            });
        });
        builder.addCase(register.pending, (state, action) => {
            state.register_status = "loading";
        });
        builder.addCase(register.fulfilled, (state, action) => {
            state.token = action.payload.token;
            state.user = action.payload.user;
            state.register_status = "idle";
            sessionStorage.removeItem("login_register_extra_params");

            TagManager.dataLayer({
                dataLayer: {
                    event: "user_registration",
                    user: {
                        id: action.payload.user?.membership_number,
                        method: "email"
                    }

                }
            });
        });
        builder.addCase(register.rejected, (state, action) => {
            state.register_status = "idle";
        });
        builder.addCase(getSiteAccessToken.pending, (state, action) => {
            state.site_access_token_status = "loading";
        });
        builder.addCase(getSiteAccessToken.fulfilled, (state, action) => {
            state.site_access_token = action.payload.access_token;
            state.site_access_token_status = "idle";
        });
        builder.addCase(getSiteAccessToken.rejected, (state, action) => {
            state.site_access_token_status = "idle";
        });
        builder.addCase(getProfile.pending, (state, action) => {
            state.profile_loading = "loading";
        });
        builder.addCase(getProfile.fulfilled, (state, action) => {
            state.user_profile = action.payload.profile;
            state.profile_loading = "idle";
        });
        builder.addCase(getProfile.rejected, (state, action) => {
            state.profile_loading = "idle";
        });
        builder.addCase(getPointBalance.fulfilled, (state, action) => {
            state.point_balances = action.payload.point_balances;
        });
        builder.addCase(getPointToCashRate.fulfilled, (state, action) => {
            state.point_to_cash_rates = action.payload.point_to_cash_rates;
        });
        builder.addCase(getPointTransactionHistory.pending, (state, action) => {
            state.point_transactions_status = "loading";
        });
        builder.addCase(
            getPointTransactionHistory.fulfilled,
            (state, action) => {
                state.point_transactions_status = "idle";
                state.point_transactions = action.payload.point_transactions;
                state.total_point_transactions = action.payload.total_rows;
            }
        );
        builder.addCase(pointTransfer.pending, (state, action) => {
            state.point_transfer_status = "loading";
            state.point_transfer_result = {};
            state.point_transfer_result_show = false;
        });
        builder.addCase(pointTransfer.fulfilled, (state, action) => {
            state.point_transfer_status = "idle";
            state.point_transfer_result = action.payload;
            state.point_transfer_result_show = true;
        });
        builder.addCase(pointTransfer.rejected, (state, action) => {
            state.point_transfer_status = "idle";
            state.point_transfer_result = {};
            state.point_transfer_result_show = false;
        });

        builder.addCase(getMemberAddress.pending, (state, action) => {
            state.member_addresses_status = "loading";
        });
        builder.addCase(getMemberAddress.fulfilled, (state, action) => {
            state.member_addresses_status = "idle";
            state.member_addresses = action.payload.addresses;
            state.total_member_addresses = action.payload.total_rows;
        });
        builder.addCase(getMemberAddress.rejected, (state, action) => {
            state.member_addresses_status = "idle";
        });
        builder.addCase(getOTP.pending, (state, action) => {
            state.otp_get_status = "loading";
        });
        builder.addCase(getOTP.fulfilled, (state, action) => {
            state.otp_get_status = "idle";
        });
        builder.addCase(getOTP.rejected, (state, action) => {
            state.otp_get_status = "idle";
        });

        builder.addCase(submitOTP.pending, (state, action) => {
            state.otp_submit_status = "loading";
        });
        builder.addCase(submitOTP.fulfilled, (state, action) => {
            state.otp_submit_status = "idle";
            state.token = action.payload.token;
            state.user = action.payload.user;
            sessionStorage.removeItem("login_register_extra_params");

            TagManager.dataLayer({
                dataLayer: {
                    event: "user_registration",
                    user: {
                        id: action.payload.user?.membership_number,
                        method: "phone-otp"
                    }

                }
            });
        });
        builder.addCase(submitOTP.rejected, (state, action) => {
            state.otp_submit_status = "idle";
        });
        builder.addCase(clickCampaignLink.fulfilled, (state, action) => {
            sessionStorage.removeItem("login_register_extra_params");
        });
    },
});

export const login = createAsyncThunk(
    "user/login",
    async ({ email, password }) => {
        const extraParams = sessionStorage.getItem(
            "login_register_extra_params"
        );
        const response = await axios.post(
            process.env.REACT_APP_API_URL + "/login.php",
            {
                client_id: CLIENT_ID,
                email,
                password,
                extra_params: JSON.parse(extraParams),
            }
        );

        return handleErrorRes(response);
    }
);

export const register = createAsyncThunk("user/register", async (values) => {
    const extraParams = sessionStorage.getItem("login_register_extra_params");
    const response = await axios.post(
        process.env.REACT_APP_API_URL + "/register.php",
        {
            client_id: CLIENT_ID,
            ...values,
            extra_params: JSON.parse(extraParams),
        }
    );

    return handleErrorRes(response);
});

export const forgotPassword = createAsyncThunk(
    "user/forgotPassword",
    async ({ email }) => {
        const response = await axios.post(
            process.env.REACT_APP_API_URL + "/forgot_password.php",
            {
                client_id: CLIENT_ID,
                email,
            }
        );

        return handleErrorRes(response);
    }
);

export const resetPassword = createAsyncThunk(
    "user/resetPassword",
    async ({ token, password }) => {
        const response = await axios.post(
            process.env.REACT_APP_API_URL + "/reset_password.php",
            {
                client_id: CLIENT_ID,
                token,
                password,
            }
        );

        return handleErrorRes(response);
    }
);

export const getSiteAccessToken = createAsyncThunk(
    "user/getSiteAccessToken",
    async (_, { getState }) => {
        const userState = getState().user;
        const response = await axios.post(
            process.env.REACT_APP_API_URL + "/access_token_get.php",
            {
                client_id: CLIENT_ID,
                access_token: userState.site_access_token || "",
            }
        );

        return handleErrorRes(response);
    }
);

export const getProfile = createAsyncThunk(
    "user/getProfile",
    async (_, { getState, dispatch, rejectWithValue }) => {
        const userState = getState().user;
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/profile_get.php",
                {
                    client_id: CLIENT_ID,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userState.token}`,
                    },
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const getPointBalance = createAsyncThunk(
    "user/getPointBalance",
    async (_, { getState, dispatch, rejectWithValue }) => {
        try {
            const userState = getState().user;
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/point_balance_get.php",
                {
                    client_id: CLIENT_ID,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userState.token}`,
                    },
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const getPointToCashRate = createAsyncThunk(
    "user/getPointToCashRate",
    async (_, { getState, dispatch, rejectWithValue }) => {
        try {
            const userState = getState().user;
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/point_config_get.php",
                {
                    client_id: CLIENT_ID,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userState.token}`,
                    },
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const getPointTransactionHistory = createAsyncThunk(
    "user/getPointTransactionHistory",
    async ({ limit, offset }, { getState, dispatch, rejectWithValue }) => {
        try {
            const userState = getState().user;
            const response = await axios.post(
                process.env.REACT_APP_API_URL +
                "/point_transaction_history_get.php",
                {
                    limit,
                    offset,
                    client_id: CLIENT_ID,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userState.token}`,
                    },
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const pointTransfer = createAsyncThunk(
    "user/pointTransfer",
    async (
        { from_amount, onHideModal },
        { getState, dispatch, rejectWithValue }
    ) => {
        try {
            const userState = getState().user;
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/point_transfer.php",
                {
                    client_id: CLIENT_ID,
                    point_type_from: BEANS_POINT_TYPE,
                    point_type_to: CREDIT_POINT_TYPE,
                    from_amount,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userState.token}`,
                    },
                }
            );

            dispatch(getPointBalance());
            dispatch(getPointTransactionHistory({ limit: 10, offset: 0 }));
            dispatch(getProfile());

            onHideModal?.();

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const getMemberAddress = createAsyncThunk(
    "user/getMemberAddress",
    async (_, { getState, dispatch, rejectWithValue }) => {
        try {
            const userState = getState().user;
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/member_address_get.php",
                {
                    limit: 999,
                    offset: 0,
                    client_id: CLIENT_ID,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userState.token}`,
                    },
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const getOTP = createAsyncThunk(
    "user/getOTP",
    async ({ phone, area_code }, { getState, dispatch, rejectWithValue }) => {
        try {
            const userState = getState().user;
            console.log(phone);
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/otp_get.php",
                {
                    client_id: CLIENT_ID,
                    phone,
                    area_code,
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const submitOTP = createAsyncThunk(
    "user/submitOTP",
    async (
        { phone, area_code, otp },
        { getState, dispatch, rejectWithValue }
    ) => {
        try {
            const userState = getState().user;
            const extraParams = sessionStorage.getItem(
                "login_register_extra_params"
            );
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/otp_submit.php",
                {
                    client_id: CLIENT_ID,
                    phone,
                    area_code,
                    otp,
                    extra_params: JSON.parse(extraParams),
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const clickCampaignLink = createAsyncThunk(
    "user/clickCampaignLink",
    async (_, { getState, dispatch, rejectWithValue }) => {
        const userState = getState().user;
        try {
            const extraParams = sessionStorage.getItem(
                "login_register_extra_params"
            );
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/campaign_link_click.php",
                {
                    client_id: CLIENT_ID,
                    extra_params: JSON.parse(extraParams),
                },
                {
                    headers: {
                        Authorization: `Bearer ${userState.token}`,
                    },
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const logoutAndFetchAccessToken = createAsyncThunk(
    "cart/logoutAndFetchAccessToken",
    async (_, { dispatch, getState }) => {
        dispatch(logoutUser());
        dispatch(getSiteAccessToken());
    }
);

export const { setToken, setUser, logoutUser, setPointTransferResultShow } =
    userSlice.actions;
export default userSlice.reducer;
