import { Suspense, lazy, useEffect, useState } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoute";
import { useDispatch, useSelector } from "react-redux";
import { getSiteAccessToken } from "./store/slices/user-slice";
import TokenCheckWrapper from "./routes/TokenCheckWrapper";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-W82QVKVR'
}

TagManager.initialize(tagManagerArgs)

// home pages
const HomeMain = lazy(() => import("./pages/home/HomeMain"));

// shop pages
const Shop = lazy(() => import("./pages/shop/Shop"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

// auth pages
const OTPLogin = lazy(() => import("./pages/auth/OTPLogin"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const ForgotPassword = lazy(() => import("./pages/other/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/other/ResetPassword"));

const OurStory = lazy(() => import("./pages/other/OurStory"));
const OurCoffee = lazy(() => import("./pages/other/OurCoffee"));
const OurShops = lazy(() => import("./pages/other/OurShops"));
const OurAchievements = lazy(() => import("./pages/other/OurAchievements"));
const OurSustainabilityPractises = lazy(() =>
    import("./pages/other/OurSustainabilityPractises")
);
const Subscription = lazy(() => import("./pages/other/Subscription"));
const StoreLocations = lazy(() => import("./pages/other/StoreLocations"));
const LikeAChamp = lazy(() => import("./pages/other/LikeAChamp"));
const CorporateCorner = lazy(() => import("./pages/other/CorporateCorner"));
const Careers = lazy(() => import("./pages/other/Careers"));
const Delivery = lazy(() => import("./pages/other/Delivery"));
const Refund = lazy(() => import("./pages/other/Refund"));
const TermsOfService = lazy(() => import("./pages/other/TermsOfService"));
const PrivacyPolicy = lazy(() => import("./pages/other/PrivacyPolicy"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const CreditShop = lazy(() => import("./pages/shop/CreditShop"));
const OrderConfirmation = lazy(() => import("./pages/other/OrderConfirmation"));
const ComingSoon = lazy(() => import("./pages/other/ComingSoon"));
const CheckoutLogin = lazy(() => import("./pages/other/CheckoutLogin"));

if (process.env.NODE_ENV === "production") {
    console.log = () => { };
}

const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSiteAccessToken());
    }, [dispatch]);

    return (
        <Router>
            <TokenCheckWrapper>
                <ScrollToTop>
                    <Suspense
                        fallback={
                            <div className="flone-preloader-wrapper">
                                <div className="flone-preloader">
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        }
                    >
                        <Routes>
                            {/* Home pages */}
                            <Route
                                path={process.env.PUBLIC_URL + "/"}
                                element={<HomeMain />}
                            />

                            {/* Shop pages */}
                            <Route
                                path={process.env.PUBLIC_URL + "/shop"}
                                element={<Shop />}
                            />
                            <Route
                                path={
                                    process.env.PUBLIC_URL +
                                    "/shop/:category_id"
                                }
                                element={<Shop />}
                            />

                            {/* Shop product pages */}
                            <Route
                                path={process.env.PUBLIC_URL + "/product/:id"}
                                element={<Product />}
                            />

                            {/* Other pages */}
                            <Route
                                path={process.env.PUBLIC_URL + "/about"}
                                element={<About />}
                            />
                            <Route
                                path={process.env.PUBLIC_URL + "/contact"}
                                element={<Contact />}
                            />
                            <Route
                                path={process.env.PUBLIC_URL + "/my-account"}
                                element={
                                    <PrivateRoute>
                                        <MyAccount />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path={
                                    process.env.PUBLIC_URL + "/login-register"
                                }
                                element={<LoginRegister />}
                            />

                            <Route
                                path={process.env.PUBLIC_URL + "/otp-login"}
                                element={<OTPLogin />}
                            />

                            <Route
                                path={
                                    process.env.PUBLIC_URL + "/forgot-password"
                                }
                                element={<ForgotPassword />}
                            />

                            <Route
                                path={
                                    process.env.PUBLIC_URL + "/reset-password"
                                }
                                element={<ResetPassword />}
                            />

                            <Route
                                path={process.env.PUBLIC_URL + "/our-story"}
                                element={<OurStory />}
                            />
                            <Route
                                path={process.env.PUBLIC_URL + "/our-coffee"}
                                element={<OurCoffee />}
                            />
                            <Route
                                path={process.env.PUBLIC_URL + "/our-shops"}
                                element={<OurShops />}
                            />
                            <Route
                                path={
                                    process.env.PUBLIC_URL + "/our-achievements"
                                }
                                element={<OurAchievements />}
                            />
                            <Route
                                path={
                                    process.env.PUBLIC_URL +
                                    "/our-sustainability-practises"
                                }
                                element={<OurSustainabilityPractises />}
                            />
                            <Route
                                path={process.env.PUBLIC_URL + "/subscription"}
                                element={<Subscription />}
                            />
                            <Route
                                path={process.env.PUBLIC_URL + "/locations"}
                                element={<StoreLocations />}
                            />
                            <Route
                                path={process.env.PUBLIC_URL + "/like-a-champ"}
                                element={<LikeAChamp />}
                            />
                            <Route
                                path={
                                    process.env.PUBLIC_URL + "/corporate-corner"
                                }
                                element={<CorporateCorner />}
                            />
                            <Route
                                path={process.env.PUBLIC_URL + "/careers"}
                                element={<Careers />}
                            />
                            <Route
                                path={process.env.PUBLIC_URL + "/delivery"}
                                element={<Delivery />}
                            />
                            <Route
                                path={process.env.PUBLIC_URL + "/refund"}
                                element={<Refund />}
                            />
                            <Route
                                path={
                                    process.env.PUBLIC_URL + "/terms-of-service"
                                }
                                element={<TermsOfService />}
                            />
                            <Route
                                path={
                                    process.env.PUBLIC_URL + "/privacy-policy"
                                }
                                element={<PrivacyPolicy />}
                            />

                            <Route
                                path={process.env.PUBLIC_URL + "/cart"}
                                element={<Cart />}
                            />
                            <Route
                                path={process.env.PUBLIC_URL + "/wishlist"}
                                element={<Wishlist />}
                            />
                            <Route
                                path={process.env.PUBLIC_URL + "/compare"}
                                element={<Compare />}
                            />
                            <Route
                                path={
                                    process.env.PUBLIC_URL + "/checkout-login"
                                }
                                element={<CheckoutLogin />}
                            />
                            <Route
                                path={process.env.PUBLIC_URL + "/checkout"}
                                element={<Checkout />}
                            />
                            <Route
                                path={
                                    process.env.PUBLIC_URL +
                                    "/order-confirmation/:order_token"
                                }
                                element={<OrderConfirmation />}
                            />

                            <Route
                                path={process.env.PUBLIC_URL + "/coming-soon"}
                                element={<ComingSoon />}
                            />

                            <Route
                                path={process.env.PUBLIC_URL + "/credit-shop"}
                                element={
                                    <PrivateRoute>
                                        <CreditShop />
                                    </PrivateRoute>
                                }
                            />

                            <Route path="/404" element={null} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </Suspense>
                </ScrollToTop>
            </TokenCheckWrapper>
        </Router>
    );
};

export default App;
