import axios from "axios";
import { CLIENT_ID, WEBSITE_SECTION_KEYS } from "../../helpers/config";
import { handleErrorRes } from "../../helpers/utils";
import { logoutUser } from "./user-slice";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const appSlice = createSlice({
    name: "app",
    initialState: {
        site_meta_title: "",
        site_meta_description: "",
        site_meta_keywords: "",
        countries: [],
        delivery_countries: [],
        pickup_stores: [],
        delivery_timeslots: [],
        pickup_timeslots: [],
        delivery_blackout_dates: [],
        pickup_blackout_dates: [],
        website_sections: [],
        home_banners: [],
        home_subscribe_block: {},
        home_more_about_us: {},
        home_category_blocks: [],
        home_our_purpose_block: {},
        about_us: [],
        our_story: [],
        our_coffee: [],
        our_shops: [],
        our_achievements: [],
        our_achievements_award_images: [],
        our_sustainability_practises: [],
        store_locations: [],
        like_a_champ: [],
        corporate_corner: [],
        corporate_corner_thumbnail: [],
        subscription: [],
        client_logos: [],
        careers_blocks: [],
        delivery_blocks: [],
        refund_blocks: [],
        terms_of_service_blocks: [],
        privacy_policy_blocks: [],
        benefits: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCountries.fulfilled, (state, action) => {
            state.countries = action.payload.countries;
        });
        builder.addCase(fetchDeliveryCountries.fulfilled, (state, action) => {
            state.delivery_countries = action.payload.delivery_countries;
        });
        builder.addCase(fetchPickupStores.fulfilled, (state, action) => {
            state.pickup_stores = action.payload.stores;
        });
        builder.addCase(fetchTimeslots.fulfilled, (state, action) => {
            state.pickup_timeslots = action.payload.pickup_timeslots;
            state.delivery_timeslots = action.payload.delivery_timeslots;
        });
        builder.addCase(fetchClientBlackoutDates.fulfilled, (state, action) => {
            state.pickup_blackout_dates = action.payload.pickup_blackout_dates;
            state.delivery_blackout_dates =
                action.payload.delivery_blackout_dates;
        });
        builder.addCase(fetchWebsiteSections.fulfilled, (state, action) => {
            state.website_sections = action.payload.website_sections;
            const sections = action.payload.website_sections;
            state.home_banners =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.HOME_BANNER
                ) || [];
            state.home_subscribe_block =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.HOME_SUBSCRIBE_BLOCK
                )?.[0] || {};
            state.home_more_about_us =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.HOME_MORE_ABOUT_US
                )?.[0] || {};
            state.home_category_blocks =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.HOME_CATEGORY
                ) || [];
            state.home_our_purpose_block =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.HOME_OUR_PURPOSE
                )?.[0] || {};
            state.about_us =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.ABOUT_US
                ) || [];
            state.our_story =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.OUR_STORY
                ) || [];
            state.our_coffee =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.OUR_COFFEE
                ) || [];
            state.our_shops =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.OUR_SHOPS
                ) || [];
            state.our_achievements =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.OUR_ACHIEVEMENTS
                ) || [];
            state.our_achievements_award_images =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.OUR_ACHIEVEMENTS_AWARD_IMAGES
                ) || [];
            state.our_sustainability_practises =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.OUR_SUSTAINABILITY_PRACTISES
                ) || [];
            state.store_locations =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.STORE_LOCATIONS
                ) || [];
            state.like_a_champ =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.LIKE_A_CHAMP
                ) || [];
            state.corporate_corner_thumbnail =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.CORPORATE_CORNER_THUMBNAIL
                ) || [];
            state.subscription =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.SUBSCRIPTION
                ) || [];
            state.client_logos =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.CLIENT_LOGO
                ) || [];
            state.careers_blocks =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.CAREERS
                ) || [];
            state.delivery_blocks =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.DELIVERY
                ) || [];
            state.refund_blocks =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.REFUND
                ) || [];
            state.terms_of_service_blocks =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.TERMS_OF_SERVICE
                ) || [];
            state.privacy_policy_blocks =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.PRIVACY_POLICY
                ) || [];
            state.corporate_corner =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.CORPORATE_CORNER
                ) || [];
            state.benefits =
                findWebsiteSectionByKey(
                    sections,
                    WEBSITE_SECTION_KEYS.BENEFITS
                ) || [];
        });
        builder.addCase(fetchSEOConfig.fulfilled, (state, action) => {
            state.site_meta_title = action.payload.site_meta_title;
            state.site_meta_description = action.payload.site_meta_description;
            state.site_meta_keywords = action.payload.site_meta_keywords;
        });
    },
});

const findWebsiteSectionByKey = (sections = [], section_key) => {
    let section = sections.find((s) => s.section_key === section_key);
    return section?.section_items || [];
};

export const fetchCountries = createAsyncThunk(
    "app/fetchCountries",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/country_get.php",
                {
                    limit: 999,
                    offset: 0,
                    client_id: CLIENT_ID,
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const fetchDeliveryCountries = createAsyncThunk(
    "app/fetchDeliveryCountries",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/delivery_country_get.php",
                {
                    limit: 999,
                    offset: 0,
                    client_id: CLIENT_ID,
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const fetchTimeslots = createAsyncThunk(
    "app/fetchTimeslots",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/timeslot_get.php",
                {
                    limit: 999,
                    offset: 0,
                    client_id: CLIENT_ID,
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const fetchClientBlackoutDates = createAsyncThunk(
    "app/fetchClientBlackoutDates",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/client_blackout_date_get.php",
                {
                    limit: 999,
                    offset: 0,
                    client_id: CLIENT_ID,
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const fetchWebsiteSections = createAsyncThunk(
    "category/fetchWebsiteSections",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/website_section_get.php",
                {
                    limit: 999,
                    offset: 0,
                    client_id: CLIENT_ID,
                }
            );
            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const fetchPickupStores = createAsyncThunk(
    "app/fetchPickupStores",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/pickup_store_get.php",
                {
                    limit: 999,
                    offset: 0,
                    client_id: CLIENT_ID,
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const fetchSEOConfig = createAsyncThunk(
    "app/fetchSEOConfig",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/seo_config_get.php",
                {
                    client_id: CLIENT_ID,
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

// export const { setCategories } = appSlice.actions;
export default appSlice.reducer;
